@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  * {
    margin: 0;
    padding: 0;
  }

  body {
    background: #0c1005;
    font-family: "Inter", sans-serif;
    line-height: 1.6rem;
  }

  main {
    min-height: 100dvh;
    margin-inline: auto;
    width: min(80ch, 100%);
  }

  h1,
  h2 {
    @apply font-bold text-xl;
  }
}

a,
p {
  @apply text-green-light;
}

::selection {
  background: #6f8c2b;
}
